const getImageSet = function ({ dimensions, imageName }) {
  const { height, width } = dimensions;
  return {
    srcSet: `jpg_images/320/${imageName} 320w, jpg_images/640/${imageName} 640w, jpg_images/1024/${imageName} 1024w`,
    src: `jpg_images/1024/${imageName}`,
    sizes: '(min-width: 480px) 50vw, (min-width: 1024px) 33.3vw, 100vw',
    height,
    width,
  };
};
export default getImageSet;

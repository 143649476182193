import React from 'react';
import { HeroBanner, SvgImage } from './event-components.js';

export const HeroBannerComponent = () => (
  <HeroBanner id="hero-banner">
    {/* <div
  style={{
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/vectors/INFORMATION2.svg")',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  }}
></div> */}

    <SvgImage src="/vectors/INFORMATION2.svg" />
  </HeroBanner>
);

import React from 'react';
import PropTypes from 'prop-types';
import { googleLogin, logout } from './auth.js';

export const Login = ({ user, setUser, setLoading }) => {
  const handleLogin = async () => {
    setLoading(true);
    await googleLogin(setUser);
    setLoading(false);
  };

  const handleLogout = () => {
    setLoading(true);
    setUser(null);
    logout();
    setLoading(false);
  };

  return (
    <div style={{ padding: '50px' }}>
      {user && user.accessToken ? (
        <div>
          <div>Logged in as:</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                height: '100px',
                width: '100px',
                borderRadius: '50%',
                border: 'none',
                margin: '10px',
              }}
              src={user.photoURL}
            />
            <div>{user.displayName}</div>
          </div>
          <button style={{ margin: '10px' }} onClick={handleLogout}>
            LOGOUT
          </button>
        </div>
      ) : (
        <button onClick={handleLogin}>LOGIN</button>
      )}
    </div>
  );
};

Login.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    photoURL: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

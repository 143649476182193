import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';
import {
  GlobalContainer,
  GlobalContent,
  HeaderImgSvg,
  SmButton,
} from '../Global/global-components';

export const Container = styled(GlobalContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${GlobalStyles.color1};
`;

export const Content = styled(GlobalContent)`
  padding-top: 1em;
  color: ${GlobalStyles.textColor3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${GlobalStyles.textColor1};
  min-height: 620px;
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(20vh);
  padding-bottom: 30px;

  @media ${GlobalStyles.screenSize.tablet} {
    padding-bottom: 0;
    background-color: ${GlobalStyles.color4};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media ${GlobalStyles.screenSize.tablet} {
    height: 450px;
  }
`;

// export const HeaderImg = styled(HeaderImgSvg)`
//   max-width: 50%;
//   display: none;

//   @media ${GlobalStyles.screenSize.tablet} {
//     display: inline-flex;
//   }
// `;

export const HeaderImg = styled.div`
  display: none;
  min-width: 50%;
  height: 100%;

  background-image: url('jpg_images/1024/IMG_6611.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${GlobalStyles.screenSize.tablet} {
    display: inline-flex;
  }
`;

export const HeaderText = styled(HeaderImgSvg)`
  padding: 0;
  height: 100%;

  @media ${GlobalStyles.screenSize.tablet} {
    max-width: 35%;
    padding: 0 3em 0 3em;
  }
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  @media ${GlobalStyles.screenSize.tablet} {
    padding: 5px 4px;
  }
`;

// export const HeaderText = styled.div`
//   padding: 0;
//   height: 100%;
//   background-image: url('images/vectors/RSVP.svg');
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;

//   @media ${GlobalStyles.screenSize.tablet} {
//     max-width: 35%;
//     margin: 0 3em 0 3em;
//   }
// `;

export const HeaderSpacer = styled.div`
  display: none;

  @media ${GlobalStyles.screenSize.laptop} {
    display: block;
    width: 10em;
  }

  @media ${GlobalStyles.screenSize.laptopL} {
    display: block;
    width: 20em;
  }

  @media ${GlobalStyles.screenSize.desktop} {
    display: block;
    width: 30em;
  }

  @media ${GlobalStyles.screenSize.desktopL} {
    display: block;
    width: 40em;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  @media ${GlobalStyles.screenSize.laptop} {
    width: 60%;
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const FormInstructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% 10% 2% 10%;
  color: ${GlobalStyles.color1};
`;

export const AttendeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const GuestInputContainer = styled.div`
  width: 100%;
  position: relative;
  height: 3.5em;
  margin: 1em 0;
`;

export const GuestActionButton = styled.button`
  border: none;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const GuestActionDropdown = styled.div`
  position: absolute;
  background-color: ${GlobalStyles.color1};
  height: 7em;
  width: 100%;
  z-index: 3;
  box-shadow: 1px 1px 2px black;
  box-sizing: border-box;
`;

export const DropdownItem = styled.div`
  height: 50%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :hover {
    background-color: ${GlobalStyles.color4};
  }
  :focus {
    background-color: ${GlobalStyles.color4};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: 4em;
  margin: 0;
`;
/* width: 80%;
  @media ${GlobalStyles.screenSize.tablet} {
    width: ${GlobalStyles.rsvpFormMaxWidth};
  } */
export const LineInput = styled.input`
  background-color: ${GlobalStyles.color1};
  border: none;
  width: calc(100% - 2em);
  height: calc(100% - 0.5em);
  padding: 0.25em 1em;
  text-align: left;
  font-size: 16px;
  color: black;
  font-family: ${GlobalStyles.fontFamily};
  text-overflow: ellipsis;
  ::placeholder {
    color: #3a3a3a;
    font-family: ${GlobalStyles.fontFamily};
  }
  :focus {
    outline-color: ${GlobalStyles.color4};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px ${GlobalStyles.color1} inset !important;
  }
`;

export const EmailInput = styled(LineInput)`
  position: relative;
  height: 3em;
  margin: 1em 0;
`;

export const RemoveButton = styled.button`
  border: none;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
`;
export const MessageBox = styled.textarea`
  margin: 1em 0;
  background-color: ${GlobalStyles.color1};
  border: none;
  width: calc(100% - 1.9em);
  height: 15em;
  padding: 1em 1em;
  text-align: left;
  font-size: 16px;
  font-family: ${GlobalStyles.fontFamily};

  ::placeholder {
    color: #3a3a3a;
    font-family: ${GlobalStyles.fontFamily};
  }
`;

export const RsvpButton = styled(SmButton)`
  border: 4px solid ${GlobalStyles.color1};
  color: ${GlobalStyles.color1};
  min-width: 100px;
  max-width: 250px;
  @media ${GlobalStyles.screenSize.laptop} {
    max-width: 400px;
  }
  margin: 2em;
`;

export const RadioGroup = styled.div`
  width: 100%;
  ${'' /* height: 2.5em; */}
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${GlobalStyles.color1};
  margin: 2em;

  @media ${GlobalStyles.screenSize.custom2} {
    flex-direction: row;
  }
  ${
    '' /* @media ${GlobalStyles.screenSize.mobileL} {
    flex-direction: row;
  } */
  }
  @media ${GlobalStyles.screenSize.tablet} {
    padding: 0;
  }
`;
export const RadioLabel = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  margin: 0.5em;
  width: 100%;
  font-size: 16px;

  @media ${GlobalStyles.screenSize.custom2} {
    flex-direction: row;
    margin: 0;
    font-size: 14px;
  }

  @media ${GlobalStyles.screenSize.tablet} {
    font-size: 16px;
  }
  @media (min-width: 620px) {
    ${'' /* white-space: nowrap; */}
    font-size: 18px;
  }

  @media ${GlobalStyles.screenSize.laptopL} {
    max-width: 50%;
    font-size: 20px;
  }
  @media ${GlobalStyles.screenSize.laptopL} {
    font-size: 24px;
  }

  @media ${GlobalStyles.screenSize.desktop} {
    font-size: 1.5em;
  }

  @media ${GlobalStyles.screenSize.desktopL} {
    font-size: 2em;
  }
`;

export const RadioLabelAlt = styled(RadioLabel)`
  @media ${GlobalStyles.screenSize.custom2} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const RadioBox = styled.div`
  border: none;
  border-radius: 0;
  ${'' /* border: 1px solid grey; */}
  height: 2em;
  width: 2em;
  color: grey;
  background-color: ${GlobalStyles.color1};
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2em;
  min-height: 2em;
`;

export const RadioBoxSelected = styled(RadioBox)`
  background-color: ${GlobalStyles.color3};
`;

export const MobileSpacer = styled.div`
  width: 4em;
  @media ${GlobalStyles.screenSize.laptop} {
    display: none;
  }
`;

export const CheckIcon = styled.img`
  height: 200px;
  width: 200px;
  margin: 50px;
`;

export const ConfirmationText = styled.div`
  font-family: Futura;
  font-style: normal;
  font-weight: 450;
  font-size: ${GlobalStyles.fontSize3};
  color: #e0dac1;
  margin: 5% 0;
`;

export const ConfirmationTextMinor = styled(ConfirmationText)`
  font-size: 20px;
  font-style: italic;
`;

import { functions } from '../../init';
import { httpsCallable } from 'firebase/functions';

export const validateAdminUser = async ({ email, uid }) => {
  try {
    const checkApprovedUser = await httpsCallable(
      functions,
      'checkApprovedUser'
    );
    const { data } = await checkApprovedUser({ email, uid });
    return data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const loginAttempt = async (data) => {
  try {
    const recordLoginAttempt = await httpsCallable(
      functions,
      'recordLoginAttempt'
    );
    const { uid, email } = data.user;
    const result = await recordLoginAttempt({
      uid,
      email,
    });
    return result;
  } catch (e) {
    console.error(e);
    return { success: false, e };
  }
};

export const getAttendees = async (userData) => {
  try {
    const getAttendeeData = await httpsCallable(functions, 'getAttendees');
    const payload = {
      user: {
        email: userData.user.email,
        uid: userData.user.uid,
      },
    };
    const { data } = await getAttendeeData(payload);
    if (data.success) {
      return data.attendees;
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

import styled from 'styled-components';
import {
  GlobalContainer,
  GlobalHeroBanner,
} from '../Global/global-components.js';
import GlobalStyles from '../Global/GlobalStyles.js';
// import Slider from 'react-slick';

export const GalleryContainer = styled(GlobalContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${GlobalStyles.color1};
  min-height: calc(100vh - ${GlobalStyles.mobileBarHeight});
  padding-top: 2em;

  @media ${GlobalStyles.screenSize.tablet} {
    min-height: calc(100vh - ${GlobalStyles.totalBarHeight});
  }
  @media ${GlobalStyles.screenSize.laptop} {
    width: calc(100% - 4em);
    min-height: calc(100vh - ${GlobalStyles.totalBarHeight} - 4em);

    padding: 2em;
  }
`;

export const CarouselContainer = styled.div`
  width: 90%;
  margin: 2rem auto;
`;

// export const CarouselSlider = styled(Slider)`
//   display: flex;
//   align-items: center;
// `;

export const CarouselSlide = styled.div`
  display: 'flex';
  align-items: 'center';
  height: 100%;
  align-self: 'center';
`;

export const HeroBanner = styled(GlobalHeroBanner)`
  color: ${GlobalStyles.color1};
  font-weight: bold;
  background-color: ${GlobalStyles.textColor1};
  font-size: 64px;

  @media screen and ${GlobalStyles.screenSize.tablet} {
    font-size: 100px;
  }
`;

// export const CarouselImage = styled.div`
//     background-position: 'center';
//     background-size: 'contain';
//     background-repeat: 'no-repeat';
//     height: '60vh';
//     width: '60vh';
//     z-index: 'inherit';
//     position: 'relative';
//     max-width: calc(100vw - 73.406px);
// `;

export const CarouselCaption = styled.div`
  color: ${GlobalStyles.textColor1};
`;

export const PhotoStyle1 = styled.img`
  max-width: 75vw;
  max-height: 75vh;

  margin: 2em;
`;

export const PhotoStyle2 = styled(PhotoStyle1)`
  max-width: 1024px;
  margin: 0;
  height: auto;
`;
// @media ${GlobalStyles.screenSize.laptop} {
//   max-width: 25em;
// }

export const PhotoStyle3 = styled.div`
  max-width: 75vw;
  max-height: 75vh;
  margin: 2em;
`;

export const GalleryFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GalleryView = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  @media ${GlobalStyles.screenSize.tablet} {
    flex-direction: row;
  }
`;

export const GalleryNavigate = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
`;

export const CarouselToggle = styled.div`
  cursor: pointer;
  color: ${GlobalStyles.textColor1};
  :hover {
    text-decoration: underline;
  }
`;

export const Slides = styled.div`
  display: grid;
  > .slide {
    grid-area: 1 / -1;
  }

  > button {
    appearance: none;
    background: transparent;
    border: none;
    color: white;
    position: absolute;
    font-size: 5rem;
    width: 5rem;
    height: 5rem;
    top: 30%;
    transition: opacity 0.3s;
    opacity: 0.7;
    z-index: 5;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      left: -50%;
    }
    &:last-child {
      right: -50%;
    }
  }
`;

export const GridGallery = styled.div`
  gap: 16px;
  width: 85%;
  column-count: 1;

  @media screen and ${GlobalStyles.screenSize.mobileL} {
    column-count: 2;
  }
  @media screen and ${GlobalStyles.screenSize.tablet} {
    column-count: 3;
  }
`;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
  custom1: '1647px',
  custom2: '528px',
};

const GlobalStyles = {
  // color1: '#e64c4c',
  color1: '#E0DAC1',
  color2: '#ed5c45',
  color3: '#e37663',
  color4: '#ee9e63',
  color5: '#edc4c0',
  textColor1: '#e64c4c',
  textColor2: '#aba8ad',
  textColor3: '#303030',
  fontFamily:
    '"Futura", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  fontSize1: '5em',
  fontSize2: '3em',
  fontSize3: '2em',
  fontSize4: '1.5em',
  fontSize5: '1em',
  fontSize6: '12px',
  fontSize7: '8px',
  barHeight: '32px',
  totalBarHeight: '96px',
  mobileBarHeight: '16px',
  totalMobileBarHeight: '80px',
  maxContentWidth: '1500px',
  rsvpFormMaxWidth: '60%',
  screenSize: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
    custom1: `(min-width: ${size.custom1})`,
    custom2: `(min-width: ${size.custom2})`,
  },
};

export default GlobalStyles;

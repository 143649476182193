// this is a object class constructor that handles the queue of messages and actions for modal dialog content

class DialogModal {
  constructor(messages, updateMessages) {
    this.messages = messages;
    this.key = 0;
    this.newAction = (name, action) => {
      return new Action(name, action);
    };
    this.newMessage = ({
      title,
      message = '',
      actions = [],
      resolveText = 'Close',
      resolveAction,
    }) => {
      const messages = [...this.messages];
      const newMessage = new DialogContent({
        message: { title, message, resolveText },
        actions,
        resolve: () => {
          this.resolveOldest();
          return resolveAction();
        },
      });
      messages.push(newMessage);
      updateMessages(messages);
      return;
    };
    this.resolveOldest = () => {
      const messages = [...this.messages];
      const oldest = messages.shift();
      updateMessages(messages);
      return oldest;
    };
    this.nextMessage = () => this.messages[0];
    this.hasNextMessage = () => !!this.messages.length;
  }
}

class DialogContent {
  constructor({ message, actions = [], resolve }) {
    this.message = message.message;
    this.title = message.title;
    this.resolveText = message.resolveText;
    this.actions = actions;
    this.actions.push(new Action('resolve', resolve));
  }
}

class Action {
  constructor(name, action) {
    this.name = name;
    this.action = action;
  }
}

export default DialogModal;

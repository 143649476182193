import React from 'react';
import { FormInstructions, InstructionsContainer } from './rsvp-components.js';

export const Instructions = () => (
  <InstructionsContainer>
    <FormInstructions>
      <h1
        style={{
          font: 'Futura PT',
          fontWeight: '450',
          fontSize: '34px',
        }}
      >
        PLEASE REPLY BY THE FIRST OF AUGUST
      </h1>
      <p style={{ fontStyle: 'italic', fontSize: '20px', maxWidth: '450px' }}>
        Please RSVP all guests attending in household!
      </p>
      <p style={{ fontStyle: 'italic', fontSize: '20px', maxWidth: '475px' }}>
        *Due to limited numbers, we hope you appreciate that children are only
        invited if named.
      </p>
    </FormInstructions>
  </InstructionsContainer>
);

import React from 'react';
import { SmButton, XIcon, ClearButton } from '../Global/global-components.js';
import { Background, ModalContainer, Text } from './modal-components.js';

const Modal = ({ modal, updateModal }) => {
  // use modal class methods to manage the modal
  // then call update modal on the updated object to re-render the page

  if (modal.hasNextMessage()) {
    const nextMessage = modal.nextMessage();
    return (
      <Background id="modal-background">
        <ModalContainer>
          <h2>{nextMessage.title}</h2>

          {Array.isArray(nextMessage.message) ? (
            nextMessage.message.map((mess) => (
              <Text key={Math.random()}>{mess}</Text>
            ))
          ) : (
            <Text>{nextMessage.message}</Text>
          )}
          {nextMessage.actions.map((action, index) => {
            return (
              <SmButton onClick={action.action} key={index}>
                {action.name === 'resolve'
                  ? nextMessage.resolveText
                  : action.name}
              </SmButton>
            );
          })}
          <ClearButton
            style={{ top: '40px', right: '40px', position: 'absolute' }}
            onClick={() =>
              nextMessage.resolve
                ? nextMessage.resolve()
                : modal.resolveOldest()
            }
          >
            <XIcon />
          </ClearButton>
        </ModalContainer>
      </Background>
    );
  } else {
    return <div></div>;
  }
};

export default Modal;

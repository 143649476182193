import Styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const RegistryBox = Styled.div`
  background-color: ${GlobalStyles.color2};
  display: flex;
  justify-content:center;
  align-items: center;

  height: calc(70vh - ${GlobalStyles.barHeight});

  @media ${GlobalStyles.screenSize.laptop} {
    height: 641.59px;
  }
`;

export const ContentContainer = Styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${GlobalStyles.maxContentWidth};
  flex-direction: column;
  @media ${GlobalStyles.screenSize.laptop} {
    flex-direction: row;
  }
`;

export const TitleTextContainerCenter = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(50% - 4em);
  padding: 2em;
`;

export const TitleTextCenter = Styled.div`
  font-weight: bold;

  text-align: center;
  color: ${GlobalStyles.color1};
  padding-bottom: 16px;

  font-size: ${GlobalStyles.fontSize3};
  @media ${GlobalStyles.screenSize.tablet} {
    font-size: ${GlobalStyles.fontSize2};
  }
  @media ${GlobalStyles.screenSize.laptopL} {
    font-size: ${GlobalStyles.fontSize1};
  }
`;

export const TitleSubtext = Styled.div`
  font-weight: bold;
  color: white;
  text-align: center;
  padding-bottom: 1em;
  color: ${GlobalStyles.color1};

  font-size: ${GlobalStyles.fontSize4};
  @media ${GlobalStyles.screenSize.laptop} {
    font-size: ${GlobalStyles.fontSize3};
  }
`;

export const HeroImageContainer = Styled.div`
  display: none;
  @media ${GlobalStyles.screenSize.laptop} {
    display: flex;
    justify-content: end;
  }
  width: 50%;
  height: 100%;
`;

export const Hero = Styled.div`
  display: none;
  @media ${GlobalStyles.screenSize.laptop} {
    display: flex;
  }
  background-image: url('jpg_images/1024/IMG_6775.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 30%;
  min-width: 50%;
  height: 100%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { DayButtonSelected, DayButton } from './event-components.js';

export const Day = ({ day, clickHandler, selectedDay }) => {
  return day === selectedDay ? (
    <DayButtonSelected onClick={() => clickHandler(day)}>
      {day}
    </DayButtonSelected>
  ) : (
    <DayButton onClick={() => clickHandler(day)}>{day}</DayButton>
  );
};

Day.propTypes = {
  selectedDay: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const EventSummaryBox = styled.div`
  background-color: ${GlobalStyles.color4};
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(70vh - ${GlobalStyles.barHeight});

  @media ${GlobalStyles.screenSize.laptop} {
    padding: 0;
    height: 670px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${GlobalStyles.maxContentWidth};
  flex-direction: column;
  @media ${GlobalStyles.screenSize.laptop} {
  }
`;

export const TitleTextContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 2em;
`;

export const TitleTextCenter = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${GlobalStyles.color1};
  padding: 16px;

  font-size: ${GlobalStyles.fontSize3};
  @media ${GlobalStyles.screenSize.laptop} {
    font-size: ${GlobalStyles.fontSize2};
  }
  @media ${GlobalStyles.screenSize.custom1} {
    font-size: 70px;
  }
`;

export const TitleSubtext = styled.div`
  font-weight: bold;
  color: white;
  text-align: center;
  color: ${GlobalStyles.color1};
  font-size: ${GlobalStyles.fontSize4};
  @media ${GlobalStyles.screenSize.custom1} {
    font-size: ${GlobalStyles.fontSize3};
  }
`;

export const HeroImageContainer = styled.div`
  display: none;
  @media ${GlobalStyles.screenSize.laptop} {
    display: flex;
  }
  width: 50%;
  height: 100%;
  justify-content: start;
`;

export const Hero = styled.div`
  display: none;
  @media ${GlobalStyles.screenSize.laptop} {
    display: flex;
  }

  background-image: url('jpg_images/1024/IMG_6628.jpg');
  background-origin: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 20%;
  min-width: 50%;
  height: 100%;
`;

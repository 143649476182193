import React from 'react';
import { GalleryContainer, CarouselToggle } from './photo-components.js';
import arrayShuffler from '../../utils/arrayShuffler.js';
import photoNames from './utilities/photoListData.js';
import getImageSet from './utilities/photoListFormatter.js';
import { GridGallery, HeroBanner } from './photo-components.js';

const photos = arrayShuffler(photoNames.map(getImageSet));

class PhotoGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      photoList: photos,
      carousel: false,
      mobile: window.visualViewport.width <= 425,
    };

    this.toggleCarousel = this.toggleCarousel.bind(this);
  }

  toggleCarousel() {
    this.setState({ carousel: !this.state.carousel });
  }

  render() {
    return (
      <div>
        <HeroBanner>THIS IS US</HeroBanner>
        <GalleryContainer id="home-page-photo-gallery">
          <GridGallery>
            {this.state.photoList.map((photo) => (
              <img
                key={Math.random()}
                src={photo.src}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  display: 'inline-block',
                }}
                loading="lazy"
              ></img>
            ))}
          </GridGallery>
        </GalleryContainer>
      </div>
    );
  }
}

export default PhotoGallery;

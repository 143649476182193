import React from 'react';
import PropTypes from 'prop-types';

import {
  InfoCardContainer,
  InfoCardHeader,
  InfoCardContent1,
  InfoCardContent2,
  InfoCardAnchor,
} from './event-components.js';

export const InfoCard = ({
  index,
  title,
  content,
  content2,
  color,
  hyperlink,
}) => {
  const useHyperlink = !!hyperlink;
  const icon =
    index % 2 ? '/vectors/RedSparkle.svg' : '/vectors/OrangeSparkle.svg';

  return (
    <InfoCardContainer
      style={{
        backgroundColor: color,
      }}
    >
      <InfoCardHeader>{title}</InfoCardHeader>
      <img style={{ height: '40px' }} src={icon} />
      <InfoCardContent1>{content}</InfoCardContent1>
      {useHyperlink ? (
        <InfoCardAnchor href={hyperlink}>{content2}</InfoCardAnchor>
      ) : (
        <InfoCardContent2>{content2}</InfoCardContent2>
      )}
    </InfoCardContainer>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  content2: PropTypes.string,
  hyperlink: PropTypes.string,
  color: PropTypes.string,
  index: PropTypes.number.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ResponseRow = ({ response, index }) => {
  const { attendees, attending, email, message, timestamp } = response;
  const classname = index % 2 ? 'even-row' : 'odd-row';

  const date = timestamp ? new Date(Date.parse(timestamp)) : '';

  return (
    <tr className={classname}>
      <td>{email}</td>
      <td>
        {attendees.map((attendee) => (
          <p key={Math.random()}>{attendee}</p>
        ))}
      </td>
      <td>{attending}</td>
      <td>
        {timestamp
          ? `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`
          : 'n/a'}
      </td>
      <td>
        {timestamp ? `${date.getUTCHours()}:${date.getUTCMinutes()}` : 'n/a'}
      </td>
      <td>{message}</td>
    </tr>
  );
};

ResponseRow.propTypes = {
  response: PropTypes.shape({
    attendees: PropTypes.array.isRequired,
    attending: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
};

export const ResponsesTable = ({ attendees, validAdmin }) => {
  return validAdmin ? (
    <table id="responses-table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Attendees</th>
          <th>Response</th>
          <th>Date</th>
          <th>Time</th>
          <th
            style={{
              width: '100px',
            }}
          >
            Message
          </th>
        </tr>
      </thead>
      <tbody>
        {attendees.map((response, index) => (
          <ResponseRow key={Math.random()} index={index} response={response} />
        ))}
      </tbody>
    </table>
  ) : (
    <></>
  );
};

ResponsesTable.propTypes = {
  attendees: PropTypes.array,
  validAdmin: PropTypes.bool,
};

import React from 'react';
import {
  HeaderBox,
  HeaderContainer,
  HeaderImg,
  HeaderTextWrapper,
  HeaderText,
} from './rsvp-components.js';

export const HeaderBoxComponent = () => (
  <HeaderBox>
    <HeaderContainer>
      <HeaderImg />
      <HeaderTextWrapper>
        <HeaderText src="/vectors/RSVP.svg" />
      </HeaderTextWrapper>
    </HeaderContainer>
  </HeaderBox>
);

import React from 'react';
import PropTypes from 'prop-types';
import { ContactLink, Copyright, LinkText } from './footer-components.js';
import { GlobalBorder } from '../Global/global-components.js';

const Footer = ({ backgroundColor, color }) => {
  const hasModifiedColor = !!backgroundColor;
  return (
    <div data-testid="footer">
      <GlobalBorder>
        {hasModifiedColor ? (
          <ContactLink style={{ backgroundColor }}>
            <LinkText style={{ color }} href="mailto:holymatrimony@jarika.net">
              QUESTIONS? CONTACT US
            </LinkText>
          </ContactLink>
        ) : (
          <ContactLink>
            <LinkText href="mailto:holymatrimony@jarika.net">
              QUESTIONS? CONTACT US
            </LinkText>
          </ContactLink>
        )}
        <Copyright>© 2021-2022 by JARIKA</Copyright>
      </GlobalBorder>
    </div>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default Footer;

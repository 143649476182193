import React from 'react';
import PropTypes from 'prop-types';
import { Instructions } from './Instructions.js';
import { AttendeeCard } from './AttendeeCard.js';
import {
  Content,
  Form,
  MessageBox,
  RsvpButton,
  EmailInput,
  RadioGroup,
  RadioBox,
  RadioLabel,
  MobileSpacer,
  RadioBoxSelected,
  RadioLabelAlt,
} from './rsvp-components.js';
import { keyboardEventHandler } from './utils.js';

export const RsvpForm = ({
  onSubmit,
  handleAttendeeChange,
  attendees,
  setAttendees,
  newAttendee,
  accept,
  toggleAccept,
  decline,
  toggleDecline,
  removeAttendee,
}) => {
  const maxAttendees = 6;

  const focusAccept = () => {
    const label = document.getElementById('accept-checkbox-label');
    if (label) label.focus();
  };

  const focusDecline = () => {
    const label = document.getElementById('decline-checkbox-label');
    if (label) label.focus();
  };

  return (
    <Content className="content">
      <Instructions />
      <Form onSubmit={onSubmit}>
        {attendees.map((attendee, index) => {
          return (
            <AttendeeCard
              key={index}
              id={`attendee-${index + 1}`}
              attendee={attendee}
              handleChange={(e) => handleAttendeeChange(e, index)}
              removeAttendee={removeAttendee}
              addGuest={() => {
                if (attendees.length < maxAttendees)
                  setAttendees([...attendees, newAttendee(attendees.length)]);
              }}
              attendeeCount={attendees.length}
              maxAttendees={maxAttendees}
            />
          );
        })}
        <RadioGroup>
          <RadioLabel
            // htmlFor="attending"
            id="accept-checkbox-label"
            onClick={toggleAccept}
            tabIndex="0"
            onKeyDown={(e) =>
              keyboardEventHandler(e, {
                toggleAction: toggleAccept,
                ArrowRight: focusDecline,
              })
            }
          >
            {accept ? (
              <RadioBoxSelected value="accept">
                <img
                  src="/vectors/Checkmark1.svg"
                  style={{ height: '100%', width: '100%' }}
                />
              </RadioBoxSelected>
            ) : (
              <RadioBox value="accept" />
            )}
            <div style={{ whiteSpace: 'inherit' }}>JOYFULLY ACCEPT</div>
          </RadioLabel>
          <MobileSpacer />
          <RadioLabelAlt
            id="decline-checkbox-label"
            onClick={toggleDecline}
            tabIndex="0"
            onKeyDown={(e) =>
              keyboardEventHandler(e, {
                toggleAction: toggleDecline,
                ArrowLeft: focusAccept,
              })
            }
          >
            {decline ? (
              <RadioBoxSelected value="accept">
                <img
                  src="/vectors/Checkmark1.svg"
                  style={{ height: '100%', width: '100%' }}
                />
              </RadioBoxSelected>
            ) : (
              <RadioBox id="decline" />
            )}
            <div style={{ whiteSpace: 'inherit' }}>REGRETFULLY DECLINE</div>
          </RadioLabelAlt>
        </RadioGroup>

        <EmailInput placeholder="EMAIL *" name="email" id="rsvp-email-input" />
        <MessageBox
          placeholder="MESSAGE"
          name="message"
          maxLength="500"
          id="rsvp-message-box"
        />
        <div
          style={{
            height: '110px',
            textAlign: 'center',
          }}
        >
          <RsvpButton type="submit" /* disabled={} */>SUBMIT</RsvpButton>
        </div>
      </Form>
    </Content>
  );
};

RsvpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleAttendeeChange: PropTypes.func.isRequired,
  attendees: PropTypes.array.isRequired,
  setAttendees: PropTypes.func.isRequired,
  newAttendee: PropTypes.func.isRequired,
  accept: PropTypes.bool.isRequired,
  toggleAccept: PropTypes.func.isRequired,
  decline: PropTypes.bool.isRequired,
  toggleDecline: PropTypes.func.isRequired,
  removeAttendee: PropTypes.func.isRequired,
};

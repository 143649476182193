import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  background-color: ${GlobalStyles.color1};
  border: 1px solid ${GlobalStyles.textColor1};
  border-radius: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3em;
  max-width: 70%;
`;

export const Text = styled.div`
  margin: 1em;
`;

export const CloseButton = styled.button`
  background: rgba(0, 0, 0, 0);
  border: none;
`;

import React from 'react';
import {
  CheckIcon,
  ConfirmationText,
  ConfirmationTextMinor,
} from './rsvp-components.js';

export const Confirmation = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <CheckIcon src="/vectors/ConfirmationIcon.svg" />
    <div style={{ textAlign: 'center' }}>
      <ConfirmationText>
        THANK YOU FOR YOUR RSVP TO OUR WEDDING!
      </ConfirmationText>
      <ConfirmationTextMinor>
        You will receive an email shortly with a confirmation of your RSVP.
      </ConfirmationTextMinor>
    </div>
  </div>
);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from './GlobalStyles.js';
import { TooltipBubble } from './global-components.js';
import { AiFillCaretLeft } from 'react-icons/ai';

export const Tooltip = (props) => {
  const { top, right, bottom, left, show, id } = props;

  const style = {
    visibility: show ? 'visible' : 'invisible',
  };
  const bodyStyle = {};
  const buttonStyle = {
    fontWeight: 900,
    paddingTop: '5px',
  };
  const arrowStyle = {
    position: 'absolute',
    height: '15px',
    width: '15px',
    left: '-10px',
    top: 'calc(50% - 5px)',
    fill: GlobalStyles.color1,
  };

  if (show) if (top) style.top = top;
  if (right) style.right = right;
  if (bottom) style.bottom = bottom;
  if (left) style.left = left;

  const handleClick = () => {
    const tooltip = document.getElementById(id);
    if (tooltip) tooltip.style.opacity = 0;
    // setShow(false);
  };

  return show ? (
    <TooltipBubble id={id} style={style} onClick={handleClick}>
      <AiFillCaretLeft style={arrowStyle} className="arrow-icon" />
      <div style={bodyStyle}>{props.body}</div>
      <div style={buttonStyle}>{props.button}</div>
    </TooltipBubble>
  ) : (
    <span></span>
  );
};

Tooltip.propTypes = {
  button: PropTypes.string,
  body: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
};

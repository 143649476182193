import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../Navigation/NavBar.js';
import {
  FixedHeaderPlaceholder,
  HeaderBarContainer,
  HeaderTextContainer,
  HeaderText,
  NavContainer,
  AccentBlock,
} from './header-components.js';

const Header = () => {
  const scrollToTop = (behavior = 'instant') => {
    if (window.scrollTo) {
      window.scrollTo({ top: 0, left: 0, behavior });
    }
  };

  const clickHandler = (targetPath) => {
    const behavior =
      window.location.pathname === targetPath ? 'smooth' : 'instant';
    scrollToTop(behavior);
  };

  return (
    <FixedHeaderPlaceholder>
      <HeaderBarContainer>
        <Link
          to="/"
          style={{ textDecordation: 'none' }}
          onClick={() => clickHandler('/')}
        >
          <HeaderTextContainer>
            <AccentBlock />
            <HeaderText>JAMES + ARIKA</HeaderText>
            <div></div>
          </HeaderTextContainer>
        </Link>
        <NavContainer>
          <NavBar scrollHandler={clickHandler} />
        </NavContainer>
      </HeaderBarContainer>
    </FixedHeaderPlaceholder>
  );
};

export default Header;

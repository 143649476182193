import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from './GlobalStyles.js';

export const SpinningLoader = ({ customStyles }) => {
  const defaultStyles = {
    border: `10px solid ${GlobalStyles.color2}`,
    borderTop: `10px solid ${GlobalStyles.color1}`,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    animation: 'spin 1s ease-in-out infinite',
  };

  return <div style={{ ...defaultStyles, ...customStyles }}></div>;
};

SpinningLoader.propTypes = {
  customStyles: PropTypes.object,
};

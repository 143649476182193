export default (formData, modal) => {
  const { attendees, attending, email } = formData;

  if (Array.isArray(attendees)) {
    let nameError = false;
    attendees.forEach((attendee) => {
      const hasName = !!attendee.name;
      const nameLength = hasName ? attendee.name.length : attendee.length;
      if (!nameLength) {
        nameError = true;
      }
    });
    if (nameError) {
      modal.newMessage({
        title: 'Invalid RSVP Form',
        message: 'Please review guest names and remove any blanks.',
      });
      return false;
    }
  }

  if (attending !== 'accept' && attending !== 'decline') {
    modal.newMessage({
      title: 'Invalid RSVP Form',
      message:
        'Please review the "Accept" or "Decline" section and ensure one is selected.',
    });
    return false;
  }

  if (!email.length) {
    modal.newMessage({
      title: 'Invalid RSVP Form',
      message: 'Please add a valid email to recieve an RSVP confirmation.',
    });
    return false;
  }

  const validEmail =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );
  if (!validEmail) {
    modal.newMessage({
      title: 'Invalid RSVP Form',
      message: "Please ensure that you've provided a valid confirmation email.",
    });
    return false;
  }

  return true;
};

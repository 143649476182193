import React, { useState } from 'react';
import Header from '../Header/Header.js';
import RsvpContainer from '../RSVP/RSVP.js';
import Footer from '../Footer/Footer.js';
import Modal from '../Modal/Modal.js';
import DialogModal from '../../utils/modalContent.js';
import { HeaderBoxComponent } from '../RSVP/HeaderBoxComponent.js';
import { GlobalBorder } from '../Global/global-components.js';
import PropTypes from 'prop-types';

const RSVP = ({ mobile, db }) => {
  const [modal, updateModal] = useState([]);
  const dialog = new DialogModal(modal, updateModal);

  if (!mobile) {
    <GlobalBorder>
      <Header />
      <RsvpContainer modal={dialog} db={db} />
      <Footer />
      <Modal modal={dialog} />
    </GlobalBorder>;
  }

  return (
    <div>
      <Header />
      <RsvpContainer modal={dialog} db={db} />
      <Footer />
      <Modal modal={dialog} />
    </div>
  );
};

RSVP.propTypes = {
  mobile: PropTypes.bool,
  db: PropTypes.object,
};

export default RSVP;

import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const Row = styled.tr`
  font-size: 28px;
  line-height: 30px;
  @media screen and (${GlobalStyles.screenSize.tablet}) {
    line-height: 38px;
  }
`;

const AllTd = styled.td`
  padding: 0px;
  ${
    '' /* @media screen and ${GlobalStyles.screenSize.tablet} {
    padding: 0;
  }

  @media screen and ${GlobalStyles.screenSize.laptopL} {
    padding: 0 40px 0;
  } */
  }
`;

export const CenterSpacerData = styled.td`
  width: 6%;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    width: 0%;
  }
`;

export const SpacerData = styled(AllTd)`
  width: 2%;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    width: 2%;
  }
  @media screen and ${GlobalStyles.screenSize.laptopL} {
    width: 10%;
  }
`;

const ContentData = styled(AllTd)`
  vertical-align: top;
  width: 40%;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    width: 45%;
  }
`;

export const EventData = styled(ContentData)`
  text-align: left;
`;

export const DetailData = styled(ContentData)`
  text-align: right;
`;

export const Table = styled.table`
  width: 100%;
  padding: 40px 0 40px 0;
  border-spacing: 0 50px;

  @media screen and (${GlobalStyles.screenSize.tablet}) {
    border-spacing: 0 30px;
  }

  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const TableContentDiv = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  text-align: inherit;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    margin: 0;
    font-size: 36px;
    line-height: 46px;
  }
`;

export const ContentAnchor = styled.a`
  color: ${GlobalStyles.color1};
`;

import React, { useState } from 'react';
// import { authObserver } from './auth.js';
import { Login, ResponsesTable, LoggedInView } from './index.js';

import { downloadCsv } from '../../utils';
import { getAttendees } from './utils.js';

export const AdminView = () => {
  const [user, setUser] = useState({});
  const [attendees, setAttendees] = useState([]);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validAdmin, setValidAdmin] = useState(false);

  const isLoggedIn = () => user && user.auth;

  const download = () =>
    downloadCsv(
      attendees,
      ['Email', 'Attendees', 'Attending', 'Timestamp', 'Message'],
      'AttendeesRawData'
    );

  const requestAttendeeData = async () => {
    setLoading(true);
    try {
      if (isLoggedIn() && !requested) {
        await setRequested(true);
        const data = await getAttendees({ user });
        await setRequested(false);
        await setAttendees(data);
      } else {
        setAttendees([]);
      }
    } catch (e) {
      console.erroe(e);
    }
    setLoading(false);
  };

  const setUserData = (user) => {
    setUser(user);
    setValidAdmin(user.validAdmin);
  };

  return (
    <div>
      <h1>ADMIN USER VIEW</h1>
      <Login user={user} setUser={setUserData} setLoading={setLoading} />
      {loading ? (
        <span style={{ margin: '10px' }}>Loading, please wait...</span>
      ) : (
        <></>
      )}
      {isLoggedIn() ? (
        <div>
          <LoggedInView
            requestAttendeeData={requestAttendeeData}
            download={download}
            validAdmin={validAdmin}
          />
          <ResponsesTable attendees={attendees} validAdmin={validAdmin} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

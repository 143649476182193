export const downloadCsv = (data, header, title) => {
  var csv = '';
  header.forEach((item, index, array) => {
    csv += item;
    if (index + 1 < array.length) {
      csv += ',';
    }
  });
  csv += '\n';

  debugger;
  data.forEach((row) => {
    header.forEach((item, index, array) => {
      const dataPoint = row[item.toLowerCase()];

      const isPrimative =
        typeof dataPoint === 'string' || typeof dataPoint === 'number';
      if (Array.isArray(dataPoint)) {
        dataPoint.forEach((arrayItem, index, array) => {
          csv += `${arrayItem}`;
          if (index + 1 < array.length) {
            csv += ' + ';
          }
        });
      } else if (isPrimative) {
        csv += `${dataPoint}`;
      }

      if (index + 1 < array.length) {
        csv += ',';
      }
    });
    csv += '\n';
  });

  console.log(csv);

  var hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);

  hiddenElement.target = '_blank';
  hiddenElement.download = title + '.csv';
  hiddenElement.click();
};

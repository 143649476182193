import GlobalStyles from '../Global/GlobalStyles.js';

export const friday = [
  {
    title: 'rehearsal',
    attendees: 'Wedding Party',
    dress: 'Casual Wedding',
    time: '5pm to 6pm',
    location: 'Huntington Park',
    locationHref: 'https://goo.gl/maps/7NvVFPPVncAukhRNA',
  },
  {
    title: 'rehearsal dinner',
    attendees: 'Family and Wedding Party',
    dress: 'Casual Wedding',
    time: '6pm to 9pm',
    location: 'Fairmont Pavilion Room',
    locationHref: 'https://g.page/fairmontsf?share',
  },
];

export const SATURDAY = [];

export const saturday = [
  {
    title: 'bus service to ceremony',
    attendees: 'All Interested',
    dress: 'Cocktail/Semi-formal',
    time: '1:30pm',
    location: 'Stanford Court',
    locationHref: 'https://goo.gl/maps/f2M43FRA7BbvQJXYA',
  },
  {
    title: 'visitation hour',
    attendees: 'All Guests',
    dress: 'Cocktail/Semi-formal',
    time: '2pm to 3pm',
    location: 'SF Botanical Garden',
    locationHref: 'https://goo.gl/maps/ogAh9eFdfyvnLnYa8',
    details:
      'Admission to the botanical garden is free to all guests. Feel free to explore the park or hang out and mingle at the Celebration Pavilion! Limited refreshments will be available at the ceremony venue.',
  },
  {
    title: 'ceremony',
    attendees: 'All Guests',
    dress: 'Cocktail/Semi-formal',
    time: '3pm to 3:30pm',
    location: 'SF Botanical Garden',
    locationHref: 'https://goo.gl/maps/ogAh9eFdfyvnLnYa8',
  },
  {
    title: 'bus to reception',
    attendees: 'All Guests',
    dress: 'Cocktail/Semi-formal',
    time: '4pm',
    location: 'SF Botanical Garden',
    locationHref: 'https://goo.gl/maps/ogAh9eFdfyvnLnYa8',
  },
  {
    title: 'reception',
    attendees: 'All Guests',
    dress: 'Cocktail/Semi-formal',
    time: '4:30pm',
    location: 'Webster Hall SF',
    locationHref: 'https://goo.gl/maps/bjAVgADHMy8Cgexw7',
    details:
      'Join us for a buffet-style dinner and dancing at a historic San Francisco ballroom.',
  },
  {
    title: 'bus to hotel',
    attendees: 'All Interested',
    dress: 'Cocktail/Semi-formal',
    time: '7pm - 11pm',
    location: 'Stanford Court',
    locationHref: 'https://goo.gl/maps/f2M43FRA7BbvQJXYA',
  },
];

export const sunday = [
  {
    title: 'farewell event',
    attendees: 'All Are Welcome',
    dress: 'Casual',
    time: '10am to 2pm',
    location: 'tbd',
  },
];

export const info = [
  {
    title: 'ATTIRE',
    content:
      'Bring your dancing shoes and semi-formal/cocktail attire for the wedding itself.',

    content2: 'You do you. \n\nWe will be dressed well.',
    color: GlobalStyles.color3,
  },
  {
    title: 'STAY THE WEEKEND',
    content:
      // eslint-disable-next-line prettier/prettier
      'Stay for the weekend at the Standford Court. We’ll be there from the Friday 14th - Sunday 16th of October.  Book through the link or by email at reservations@stanfordcourt.com',
    content2: 'Book Here',
    hyperlink:
      'https://stanfordcourt.reztrip.com/classic/en/special_offer?action=show&controller=landings&locale=en&rate_code[]=SCWEDD&rate_code[]=SCWEDD&starting_page=special_offer',
    color: GlobalStyles.color4,
  },
  {
    title: 'TRANSPORTATION',
    content:
      'Nothing will be further than a 15 minute drive or 20 minute BART ride.',
    content2:
      'On the day, we will have a complimentary shuttle running back and forth from the Stanford Court Hotel, Garden, and Webster Hall SF.',
    color: GlobalStyles.color3,
  },
];

import React, { useState } from 'react';
import { Container, Content } from './rsvp-components.js';
import { HeaderBoxComponent } from './HeaderBoxComponent.js';
import { GlobalBorderVert, LoadingStateMask } from '../Global';
import { submitFailed, submitAndSave } from './utils.js';
import { Confirmation } from './Confirmation.js';
import { RsvpForm } from './Form.js';
import validateRsvpData from '../../utils/rsvpValidator.js';
import PropTypes from 'prop-types';

const RsvpContainer = ({ modal, db }) => {
  const newAttendee = (index) => {
    const attendingLabel = `guest${index}attending`;
    return { name: '', [attendingLabel]: '', index };
  };

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [attendees, setAttendees] = useState([newAttendee(0)]);
  const [accept, setAccept] = useState(false);
  const [decline, setDecline] = useState(false);

  const clearForm = () => {
    setAttendees([newAttendee(0)]);
    setAccept(false);
    setDecline(false);
    document.getElementById('rsvp-email-input').value = '';
    document.getElementById('rsvp-message-box').value = '';
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const declineSelected = decline ? 'decline' : null;
    const attending = accept ? 'accept' : declineSelected;
    const formData = {
      attendees: attendees.map((attendee) => attendee.name),
      email: e.target.email.value,
      message: e.target.message.value,
      attending,
    };
    const valid = validateRsvpData(formData, modal);
    if (valid) {
      setLoading(true);
      const response = await submitAndSave(formData);
      setLoading(false);
      if (response.success) {
        // confirmation(modal, { resolveAction: clearForm });
        clearForm();
        setSubmitted(true);
      } else {
        console.error(response.message);
        submitFailed(modal);
      }
    }
  };

  const handleAttendeeChange = (e, index) => {
    const newAttendees = [...attendees];
    const guest = {
      ...newAttendees[index],
      [e.target.name]: e.target.value,
    };
    newAttendees[index] = guest;
    setAttendees(newAttendees);
  };

  const removeAttendee = () => {
    if (attendees.length > 1) {
      const newAttendees = [...attendees];
      newAttendees.splice(-1, 1);
      setAttendees(newAttendees);
    }
  };

  const toggleAccept = () => {
    setAccept(!accept);
    if (decline) {
      setDecline(false);
    }
  };

  const toggleDecline = () => {
    setDecline(!decline);
    if (accept) {
      setAccept(false);
    }
  };

  return (
    <Container id="home-page-rsvp">
      <LoadingStateMask loading={loading} />
      <HeaderBoxComponent />
      <GlobalBorderVert>
        {submitted ? (
          <Content>
            <Confirmation />
          </Content>
        ) : (
          <RsvpForm
            setLoading={setLoading}
            onSubmit={onSubmit}
            handleAttendeeChange={handleAttendeeChange}
            attendees={attendees}
            setAttendees={setAttendees}
            newAttendee={newAttendee}
            accept={accept}
            toggleAccept={toggleAccept}
            decline={decline}
            toggleDecline={toggleDecline}
            removeAttendee={removeAttendee}
          />
        )}
      </GlobalBorderVert>
    </Container>
  );
};

RsvpContainer.propTypes = {
  modal: PropTypes.object,
  db: PropTypes.object,
};

export default RsvpContainer;

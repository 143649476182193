import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const FixedHeaderPlaceholder = styled.div`
  width: 100%;
  background-color: ${GlobalStyles.color1};
  height: ${GlobalStyles.totalMobileBarHeight};
  @media ${GlobalStyles.screenSize.laptop} {
    height: ${GlobalStyles.totalBarHeight};
  }
`;

export const HeaderBarContainer = styled.div`
  display: flex;
  padding: 2em 0;
  width: 100%;
  position: fixed;
  background-color: ${GlobalStyles.color1};
  background: linear-gradient(
    180deg,
    rgba(224, 218, 193, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  justify-content: space-between;
  z-index: 2;
  height: ${GlobalStyles.mobileBarHeight};
  @media ${GlobalStyles.screenSize.laptop} {
    height: ${GlobalStyles.barHeight};
  }
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
`;

export const HeaderTextContainer = styled.div`
  width: 310px;
  align-items: end;
  display: none;
  text-decoration: none;
  @media ${GlobalStyles.screenSize.laptop} {
    display: flex;
  }
  cursor: pointer;
  color: ${GlobalStyles.textColor1};

  :hover {
    color: ${GlobalStyles.textColor2} !important;
  }
`;

export const AccentBlock = styled.div`
  background-color: ${GlobalStyles.textColor1};
  height: ${GlobalStyles.fontSize3};
  width: 5em;
  margin-right: 1em;
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 8px;
  font-size: ${GlobalStyles.fontSize4};
  font-weight: bold;
  color: inherit;
`;

export const NavContainer = styled.div`
  width: 100%;
  @media ${GlobalStyles.screenSize.laptop} {
    width: 50%;
  }
`;

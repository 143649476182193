import React from 'react';

export const LoggedInView = ({ requestAttendeeData, download, validAdmin }) => {
  return validAdmin ? (
    <div>
      <button onClick={requestAttendeeData}>GET DATA</button>
      <button onClick={download}>DOWNLOAD RAW DATA</button>
    </div>
  ) : (
    <div>Not authorized</div>
  );
};

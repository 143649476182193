import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const ContactLink = styled.div`
  width: 100%;
  background-color: ${GlobalStyles.color4};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${GlobalStyles.fontSize5};
  height: 3em;
  color: ${GlobalStyles.textColor1};
  @media ${GlobalStyles.screenSize.laptop} {
    height: 3em;
    font-size: ${GlobalStyles.fontSize4};
  }
`;

export const LinkText = styled.a`
  color: ${GlobalStyles.color1};
  font-size: ${GlobalStyles.fontSize5};
  text-decoration: none;
  cursor:
  :hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.div`
  width: 100%;
  background-color: ${GlobalStyles.color1};
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${GlobalStyles.fontSize5};
  height: 3em;

  @media ${GlobalStyles.screenSize.laptop} {
    height: 3em;
    font-size: ${GlobalStyles.fontSize4};
  }
`;

import { app } from '../../init';
import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';

export const submitAndSave = async (formData) => {
  try {
    const functions = await getFunctions(app);

    // the line below is for local emulator dev mode only
    // connectFunctionsEmulator(functions, 'localhost', 5001);

    const submitRsvp = await httpsCallable(functions, 'submitRsvp');
    const result = await submitRsvp(formData);
    return result.data;
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};

export const showHealthMessage = (modal, setHealthMessage) => {
  setHealthMessage(true);
  const healthMessageInit = () => {
    modal.newMessage({
      title: 'HEALTH AND SAFETY',
      message: [
        'We prefer that all guests to be fully vaccinated against Covid-19 prior to October 1st, 2022.',
        'Please only RSVP “joyfully accepting” if you and your guest(s) will be fully vaccinated by the specified date or are willing to obtain a negative test within 24 hours of the event.',
        'Our wedding will comply with local guidlines and regulations, which may change between now and our wedding date.',
        '   -- James and Arika',
      ],
      resolveText: 'I UNDERSTAND',
      resolveAction: () => {
        window.localStorage.setItem(localStorageKey, true);
        setHealthMessage(true);
      },
    });
  };
  setTimeout(healthMessageInit, 500);
};

export const confirmation = (modal, { resolveAction }) => {
  modal.newMessage({
    title: 'RSVP Submitted!',
    message:
      'You will recieve a follow up email when your RSVP is processed. Another email will follow when your RSVP is reviewed and confirmed.',
    resolveAction,
  });
};

export const submitFailed = (modal) => {
  modal.newMessage({
    title: 'An error occurred!',
    message: 'Please try again later.',
  });
};

export const keyboardEventHandler = (e, customKeyActions) => {
  const { toggleAction, prevAction, nextAction } = customKeyActions;
  const navigate = (e) => {
    return e.shiftKey ? prevAction : nextAction;
  };
  const keyActionMap = {
    Enter: toggleAction,
    Space: toggleAction,
    Tab: navigate(e),
    ...customKeyActions,
  };
  const action = keyActionMap[e.code];
  if (!e.ctrl && !e.metaKey && action) {
    e.preventDefault();
    action(e);
  }
};

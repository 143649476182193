export default [
  {
    imageName: 'engagement.jpg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'engagement2.jpg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'dinnerwitharika.jpg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'portrait.jpg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'JPEGimage.jpeg',
    dimensions: {
      width: 1024,
      height: 768,
    },
  },
  {
    imageName: 'JPEGimage2.jpeg',
    dimensions: {
      width: 1024,
      height: 1536,
    },
  },
  {
    imageName: 'JPEGimage3.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'JPEGimage4.jpeg',
    dimensions: {
      width: 1024,
      height: 915,
    },
  },
  {
    imageName: 'JPEGimage5.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'JPEGimage6.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'JPEGimage7.jpeg',
    dimensions: {
      width: 1024,
      height: 1023,
    },
  },
  {
    imageName: 'JPEGimage8.jpeg',
    dimensions: {
      width: 1024,
      height: 691,
    },
  },
  {
    imageName: 'JPEGimage9.jpeg',
    dimensions: {
      width: 1024,
      height: 691,
    },
  },
  {
    imageName: 'JPEGimage10.jpeg',
    dimensions: {
      width: 1024,
      height: 768,
    },
  },
  {
    imageName: 'JPEGimage11.jpeg',
    dimensions: {
      width: 1024,
      height: 768,
    },
  },
  {
    imageName: 'JPEGimage12.jpeg',
    dimensions: {
      width: 1024,
      height: 768,
    },
  },
  {
    imageName: 'JPEGimage13.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },

  {
    imageName: 'JPEGimage15.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
  {
    imageName: 'JPEGimage16.jpeg',
    dimensions: {
      width: 1024,
      height: 1365,
    },
  },
];

// takes and array and returns its values in a new array in a random order

const arrayShuffler = (array) => {
  if (!array.length) return;

  const result = [...array];
  for (let i = 0; i < result.length; i++) {
    // swap the position of the current item with another item in the array
    const item = result[i];
    // console.log(`${item} should equal ${i + 1}`);
    // console.log(`${result.length} should be 5`);
    const targetIndex = Math.random() * result.length;

    // console.log(`${targetIndex} should be between 0 and ${array.lenth - 1}`);
    result[i] = result[Math.floor(targetIndex)];
    result[Math.floor(targetIndex)] = item;
  }
  return result;
};

// tests are below

// const arraysAreEqualAssertion = (arr1, arr2) => {
//   let match = true;
//   arr1.forEach((item, index) => {
//     if (item !== arr2[index]) {
//       match = false;
//     }
//   });
//   return match;
// };

// const array = [1, 2, 3, 4, 5];

// console.log("test input");
// console.log(array);

// const shuffled = arrayShuffler(array);

// console.log("output:");
// console.log(shuffled);

// console.log(`success: ${!arraysAreEqualAssertion(array, shuffled)}`);

export default arrayShuffler;

import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavBar, UnderlineNavItem } from './nav-components.js';

import { Link } from 'react-router-dom';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navList: [
        {
          link: '/',
          id: 'home-page-title',
          display: 'Home',
        },
        {
          link: '/event-info',
          id: 'home-page-event-info',
          display: 'Event Info',
        },
        {
          link: '/photos',
          id: 'home-page-photo-gallery',
          display: 'Photos',
        },
        {
          link: '/rsvp',
          id: 'home-page-rsvp',
          display: 'RSVP',
        },
      ],
    };
  }

  render() {
    const { navList } = this.state;
    const { scrollHandler } = this.props;
    const currentPage = window.location.pathname;

    return (
      <NavBar id="home-page-nav-bar">
        {navList.map((item) => (
          <Link
            to={item.link}
            key={item.id}
            onClick={() => scrollHandler(item.link)}
          >
            {currentPage === item.link ? (
              <UnderlineNavItem className="sidebar-link">
                {item.display.toUpperCase()}
              </UnderlineNavItem>
            ) : (
              <NavItem className="sidebar-link">
                {item.display.toUpperCase()}
              </NavItem>
            )}
          </Link>
        ))}
      </NavBar>
    );
  }
}

NavigationBar.propTypes = {
  scrollHandler: PropTypes.func.isRequired,
};

export default NavigationBar;

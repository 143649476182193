export const translateArrow = (bool) => {
  const arrow = document.querySelector('.dropdown-arrow');
  const dropdown = document.querySelector('.dropdown_menu--animated');
  const items = document.querySelectorAll('.dropdown_item--animated');

  if (bool) {
    arrow.style.transform = 'rotate(180deg)';
    dropdown.style.display = 'block';
  } else {
    arrow.style.transform = '';
    dropdown.style.height = 0;
    dropdown.style.opacity = 0;
    items.forEach((item) => {
      item.style.opacity = 0;
    });
    setTimeout(() => {
      dropdown.style.display = 'none';
      dropdown.style.height = '7em';
      dropdown.style.opacity = 1;
      items.forEach((item) => {
        item.style.opacity = 1;
      });
    }, 400);
  }
};

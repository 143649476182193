import React from 'react';
import Header from '../Header/Header.js';
import EventInfo from '../EventInfo/EventInfo.js';
import Footer from '../Footer/Footer.js';
import GlobalStyles from '../Global/GlobalStyles.js';
import { HeroBannerComponent } from '../EventInfo/HeroBanner.js';

const EventInfoPage = () => {
  return (
    <div data-testid="event-info-page">
      <Header />
      <HeroBannerComponent />
      <EventInfo />
      <Footer
        backgroundColor={GlobalStyles.textColor1}
        color={GlobalStyles.color1}
      />
    </div>
  );
};

export default EventInfoPage;

import React from 'react';
import PropTypes from 'prop-types';
import { SpinningLoader } from './SpinningLoader.js';

export const LoadingStateMask = ({ loading }) => {
  return loading ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: 'rgba(0, 0, 0, .2)',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <SpinningLoader customStyles={{ margin: '32px', zIndex: 15 }} />
    </div>
  ) : (
    <div></div>
  );
};

LoadingStateMask.propTypes = {
  loading: PropTypes.bool.isRequired,
};

import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';

export const TitleBox = styled.div`
  background-color: ${GlobalStyles.textColor1};
  height: calc(100vh - ${GlobalStyles.barHeight});
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${GlobalStyles.screenSize.laptop} {
    min-height: 565px;
    height: calc(70vh - ${GlobalStyles.barHeight});
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: ${GlobalStyles.maxContentWidth};
  flex-direction: column-reverse;
  @media ${GlobalStyles.screenSize.laptop} {
    flex-direction: row;
    min-height: 565px;

    height: calc(70vh - ${GlobalStyles.barHeight});
  }
`;

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  color: ${GlobalStyles.color1};

  align-items: center;
  @media ${GlobalStyles.screenSize.laptop} {
    align-items: end;
  }
`;

export const TitleText = styled.div`
  font-weight: bolder;
  color: ${GlobalStyles.color1};
  text-align: center;

  font-size: ${GlobalStyles.fontSize2};
  @media ${GlobalStyles.screenSize.laptop} {
    font-size: ${GlobalStyles.fontSize1};
    text-align: right;
  }
`;

export const TitleSubtext = styled.div`
  font-weight: bold;
  color: white;
  text-align: center;
  padding-bottom: 1em;
  color: ${GlobalStyles.color1};

  font-size: ${GlobalStyles.fontSize4};
  @media ${GlobalStyles.screenSize.laptop} {
    font-size: ${GlobalStyles.fontSize3};
  }
`;

export const HeroImageContainer = styled.div`
  width: 50%;
  padding: 5%;
`;

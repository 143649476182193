import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  TitleBox,
  ContentContainer,
  TitleText,
  TitleSubtext,
  TitleTextContainer,
  HeroImageContainer,
} from './title-components.js';
import { Button } from '../Global/global-components.js';

const Title = () => {
  const navigate = useNavigate();
  const navigateToRsvp = () => {
    if (window.scroll) {
      window.scroll(0, 0);
    }
    navigate('/rsvp');
  };

  return (
    <TitleBox id="home-page-title">
      <ContentContainer>
        <TitleTextContainer>
          <TitleText>JARIKA WEDDING</TitleText>
          <TitleSubtext>10.15.2022</TitleSubtext>
          <Button onClick={navigateToRsvp}>RSVP</Button>
        </TitleTextContainer>
        <HeroImageContainer>
          <img src="/vectors/DiscoBall1.svg" />
        </HeroImageContainer>
      </ContentContainer>
    </TitleBox>
  );
};

export default Title;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  SpacerData,
  CenterSpacerData,
  EventData,
  DetailData,
  TableContentDiv,
  ContentAnchor,
} from './table-components.js';

export const ScheduleCard = ({
  title,
  attendees,
  dress,
  time,
  location,
  locationHref,
  details,
}) => {
  const hasDetails = !!details;
  const hasLocationHref = !!locationHref;
  return (
    <Row>
      {/* <SpacerData /> */}
      <EventData>
        <TableContentDiv style={{ fontWeight: 'bold', lineHeight: '48px' }}>
          {title}
        </TableContentDiv>
        <TableContentDiv>{attendees}</TableContentDiv>
        <TableContentDiv>Dress: {dress}</TableContentDiv>
      </EventData>
      {/* <CenterSpacerData /> */}
      <DetailData>
        <TableContentDiv style={{ fontWeight: 'bold', lineHeight: '48px' }}>
          {time}
        </TableContentDiv>
        {locationHref ? (
          <TableContentDiv>
            <ContentAnchor href={locationHref}>{location}</ContentAnchor>
          </TableContentDiv>
        ) : (
          <TableContentDiv>{location}</TableContentDiv>
        )}
      </DetailData>
      {/* <SpacerData /> */}
      {/* {hasDetails ? <tr>{details}</tr> : ''} */}
    </Row>
  );
};

const { string } = PropTypes;

ScheduleCard.propTypes = {
  title: string.isRequired,
  attendees: string.isRequired,
  dress: string.isRequired,
  time: string.isRequired,
  location: string.isRequired,
  details: string,
  locationHref: string,
};

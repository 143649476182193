import React, { Component } from 'react';
import {
  Container,
  Content,
  Schedule,
  ContentTitle,
  InfoGrid,
  ButtonHolder,
  ScheduleContent,
  Sparkle,
} from './event-components.js';
import { Day } from './subcomponents.js';
import { ScheduleCard } from './ScheduleCard.js';
import { InfoCard } from './InfoCard.js';
import { GlobalBorderVert } from '../Global/global-components.js';
import { friday, saturday, sunday, info } from './event-data.js';
import { Table } from './table-components.js';

class EventInfo extends Component {
  constructor() {
    super();
    this.state = {
      selectedDay: 'friday',
      eventInfo: {
        friday,
        saturday,
        sunday,
      },
      info,
    };
    this.days = ['friday', 'saturday', 'sunday'];
    this.setSelectedDay = this.setSelectedDay.bind(this);
  }

  setSelectedDay(day) {
    this.setState({ selectedDay: day });
  }

  render() {
    const { selectedDay, eventInfo } = this.state;

    return (
      <Container>
        <GlobalBorderVert>
          <Content id="event-info-content">
            <Schedule>
              <ScheduleContent>
                <ContentTitle>SCHEDULE</ContentTitle>
                <ButtonHolder>
                  <Day
                    day={'friday'}
                    selectedDay={selectedDay}
                    clickHandler={this.setSelectedDay}
                  />
                  <Sparkle>
                    <img src="/vectors/OrangeSparkle.svg" />
                  </Sparkle>
                  <Day
                    day={'saturday'}
                    selectedDay={selectedDay}
                    clickHandler={this.setSelectedDay}
                  />
                  <Sparkle>
                    <img src="/vectors/OrangeSparkle.svg" />
                  </Sparkle>
                  <Day
                    day={'sunday'}
                    selectedDay={selectedDay}
                    clickHandler={this.setSelectedDay}
                  />
                </ButtonHolder>
                {/* <Date>{selectedDay}</Date> */}
                <Table>
                  <tbody>
                    {eventInfo[selectedDay].map((event) => {
                      const {
                        title,
                        attendees,
                        dress,
                        time,
                        location,
                        locationHref,
                        details,
                      } = event;
                      return (
                        <ScheduleCard
                          title={title}
                          attendees={attendees}
                          dress={dress}
                          time={time}
                          location={location}
                          locationHref={locationHref}
                          details={details}
                          key={Math.random()}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </ScheduleContent>
            </Schedule>
            <InfoGrid>
              {this.state.info.map((item, index) => {
                const { title, content, content2, hyperlink, color } = item;
                return (
                  <InfoCard
                    title={title}
                    content={content}
                    content2={content2}
                    hyperlink={hyperlink}
                    color={color}
                    key={Math.random()}
                    index={index}
                  />
                );
              })}
            </InfoGrid>
          </Content>
        </GlobalBorderVert>
      </Container>
    );
  }
}

export default EventInfo;

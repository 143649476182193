import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { loginAttempt, validateAdminUser } from './utils.js';

const provider = new GoogleAuthProvider();
const auth = getAuth();

auth.languageCode = 'en';

// export const create = async (email, password) => {
//   try {
//     const userCredential = await createUserWithEmailAndPassword(
//       auth,
//       email,
//       password
//     );
//     const user = userCredential.user;
//     // Signed in
//     // ...
//   } catch (e) {
//     // const errorCode = error.code;
//     const errorMessage = error.message;
//     console.log(errorMessage);
//     console.error(e);
//   }
// };

export const googleLogin = async (setUser) => {
  try {
    // await setPersistence(auth, browserSessionPersistence);
    const result = await signInWithPopup(auth, provider);
    // The signed-in user info.
    const { user } = result;
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = await GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const data = { token, user, credential };
    // console.log(data);
    const recordLoginResult = await loginAttempt(data);
    const { email, uid } = user;
    const validationResult = await validateAdminUser({ email, uid });
    const userData = {
      ...data,
      ...user,
      recordLoginResult,
      validationResult,
      validAdmin: validationResult.validAdmin === true,
    };
    setUser(userData);
    return userData;
  } catch (error) {
    // Handle Errors here.
    console.error(error);
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // The email of the user's account used.
    // const email = error.customData.email;
    // The AuthCredential type that was used.
    // const credential = GoogleAuthProvider.credentialFromError(error);
    return { error };
  }
};

// export const login = async (email, password) => {
//   try {
//     const userCredentials = await signInWithEmailAndPassword(
//       auth,
//       email,
//       password
//     );
//     const user = userCredential.user;
//     // Signed in
//     // ...
//     console.log(user);
//   } catch (e) {
//     // const errorCode = error.code;
//     const errorMessage = error.message;
//     console.log(errorMessage);
//   }
// };

export const logout = async (e) => signOut(auth);

export const authObserver = (onSuccess, onFail) => {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      onSuccess(user);
    } else {
      onFail(user);
    }
  });
};

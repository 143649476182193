import React from 'react';
import {
  RegistryBox,
  TitleTextContainerCenter,
  TitleTextCenter,
  // ContentContainer,
  // TitleSubtext,
  // HeroImageContainer,
  Hero,
} from './registry-components.js';
import { Button } from '../Global/global-components.js';

const Registry = () => {
  return (
    <RegistryBox id="home-page-event-registry">
      <TitleTextContainerCenter>
        <TitleTextCenter>HONEYMOON FUND</TitleTextCenter>
        <Button
          onClick={() =>
            (window.location.href = 'http://www.hitchd.com/jarika')
          }
        >
          Contribute
        </Button>
      </TitleTextContainerCenter>
      <Hero />
    </RegistryBox>
  );
};

export default Registry;

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  addDoc,
  getDocs,
  collection,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
// this line is for local development mode only
// connectFirestoreEmulator(firestore, 'localhost', 8080);
export const db = firestore;
// console.log(db);

export const analytics = getAnalytics(app);

export const functions = getFunctions(app);
// the line below is for local emulator dev mode only
// connectFunctionsEmulator(functions, 'localhost', 5001);

export const runAddDocTest = async () => {
  console.log('testing db');
  try {
    const docRef = await addDoc(collection(db, 'test'), {
      attendees: ['fake1', 'fake2'],
      attending: false,
      email: 'fakeyemail2@gmail.com',
    });

    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const runReadDocsTest = async () => {
  const querySnapshot = await getDocs(collection(db, 'test'));
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
  });
};

// runAddDocTest();

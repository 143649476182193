import React from 'react';
import Header from '../Header/Header.js';
import PhotoGallery from '../PhotoGallery/PhotoGallery.js';
import Footer from '../Footer/Footer.js';
import { GlobalBorder } from '../Global/global-components.js';

const Photos = ({ mobile }) => {
  if (!mobile) {
    <GlobalBorder data-testid="event-info-page">
      <Header />
      <PhotoGallery />
      <Footer />
    </GlobalBorder>;
  }
  return (
    <div data-testid="event-info-page">
      <Header />
      <PhotoGallery />
      <Footer />
    </div>
  );
};

export default Photos;

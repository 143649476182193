import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EventSummaryBox,
  // ContentContainer,
  TitleTextContainerCenter,
  TitleTextCenter,
  TitleSubtext,
  // HeroImageContainer,
  Hero,
} from './summary-components.js';
import { Button } from '../Global/global-components.js';

const EventSummary = () => {
  const navigate = useNavigate();
  const navigateToEventInfo = () => {
    if (window.scroll) {
      window.scroll(0, 0);
    }
    navigate('/event-info');
  };

  return (
    <EventSummaryBox id="home-page-event-summary">
      <Hero />
      <TitleTextContainerCenter>
        <TitleSubtext>JOIN US ON</TitleSubtext>
        <TitleTextCenter>10.15.2022</TitleTextCenter>
        <TitleSubtext>in</TitleSubtext>
        <TitleTextCenter>SAN FRANCISCO, CA</TitleTextCenter>
        <Button onClick={navigateToEventInfo}>See Full Details</Button>
      </TitleTextContainerCenter>
    </EventSummaryBox>
  );
};

export default EventSummary;

import React, { useState } from 'react';
import {
  GuestInputContainer,
  GuestActionButton,
  LineInput,
  GuestActionDropdown,
  DropdownItem,
} from './rsvp-components.js';
import { Tooltip } from '../Global/Tooltip';
import PropTypes from 'prop-types';
import { AiFillCaretDown } from 'react-icons/ai';
import { keyboardEventHandler } from './utils.js';
import { translateArrow } from './animationScript.js';

const tooltipBodyText = 'Click the triangle to add or remove guests.';
const tooltipButtonText = 'Got it.';

export const AttendeeCard = ({
  attendee,
  handleChange,
  addGuest,
  removeAttendee,
  attendeeCount,
  maxAttendees,
}) => {
  const { name, index } = attendee;
  const addId = `add-attendee-option-${index}`;
  const removeId = `remove-attendee-option-${index}`;
  const toggleId = `toggle-attendee-options-button-${index}`;
  const inputId = `attendee-name-input-${index}`;
  const tooltipId = `attendee-tooltip-${index}`;

  const [dropdownOpen, setDropdownState] = useState(false);
  const [showTooltip, setShowTooltip] = useState(index === 0);

  const hideTooltip = (document, cb) => {
    const tooltip = document.getElementById(tooltipId);
    if (tooltip) tooltip.style.opacity = 0;
    setTimeout(() => cb(false), 2000);
  };

  const phaseOutTooltip = () => {
    if (showTooltip) {
      setTimeout(() => hideTooltip(document, setShowTooltip), 1000);
    }
  };

  const toggleDropdown = () => {
    phaseOutTooltip();
    translateArrow(!dropdownOpen);
    setDropdownState(!dropdownOpen);
  };

  const add = () => {
    if (dropdownOpen) toggleDropdown();
    addGuest();
  };

  const remove = () => {
    if (dropdownOpen) toggleDropdown();
    removeAttendee();
  };

  const focusAdd = () => {
    const option = document.getElementById(addId);
    if (option) option.focus();
  };

  const focusRemove = () => {
    const option = document.getElementById(removeId);
    if (option) option.focus();
  };

  const focusToggle = () => {
    const toggle = document.getElementById(toggleId);
    if (toggle) toggle.focus();
  };

  // const focusInput = () => {
  //   const input = document.getElementById(inputId);
  //   if (input) input.focus();
  // };

  // const handleTooltipDisplay = (bool) => {
  //   if (bool) {
  //     setShowTooltip(true);
  //   } else {

  //   }
  // };

  if (index === attendeeCount - 1) {
    return (
      <GuestInputContainer>
        <LineInput
          id={inputId}
          name="name"
          value={name}
          onChange={handleChange}
          placeholder={`GUEST ${index + 1} FULL NAME *`}
          onBlur={phaseOutTooltip}
        />
        <GuestActionButton
          id={toggleId}
          type="button"
          onClick={() => {
            toggleDropdown();
          }}
          onKeyDown={(e) =>
            keyboardEventHandler(e, {
              ArrowDown: dropdownOpen ? focusAdd : toggleDropdown,
              ArrowRight: dropdownOpen ? undefined : toggleDropdown,
              ArrowUp: dropdownOpen ? toggleDropdown : undefined,
              ArrowLeft: dropdownOpen ? toggleDropdown : undefined,
              Tab: dropdownOpen ? toggleDropdown : undefined,
            })
          }
        >
          <AiFillCaretDown
            className="dropdown-arrow"
            style={{
              transition: 'transform 300ms ease-in-out',
              height: '18px',
              width: '18px',
            }}
          />
        </GuestActionButton>

        <GuestActionDropdown className="dropdown_menu--animated">
          <DropdownItem
            id={addId}
            className="dropdown_item--animated"
            onClick={add}
            tabIndex="0"
            onKeyDown={(e) =>
              keyboardEventHandler(e, {
                toggleAction: add,
                ArrowDown: focusRemove,
                ArrowUp: focusToggle,
              })
            }
          >
            <span style={{ width: '1em' }}></span>
            {attendeeCount >= maxAttendees
              ? '*MAX GUEST COUNT REACHED'
              : 'ADD ANOTHER GUEST'}
          </DropdownItem>
          <DropdownItem
            id={removeId}
            className="dropdown_item--animated remove-guest"
            onClick={remove}
            tabIndex="0"
            onKeyDown={(e) =>
              keyboardEventHandler(e, {
                toggleAction: remove,
                ArrowUp: focusAdd,
              })
            }
          >
            <span style={{ width: '1em' }}></span>REMOVE GUEST
          </DropdownItem>
        </GuestActionDropdown>
        <Tooltip
          body={tooltipBodyText}
          button={tooltipButtonText}
          show={showTooltip}
          id={tooltipId}
        />
      </GuestInputContainer>
    );
  }
  return (
    <GuestInputContainer>
      <LineInput
        name="name"
        value={name}
        onChange={handleChange}
        placeholder={`GUEST ${index + 1} FULL NAME *`}
      />
    </GuestInputContainer>
  );
};

AttendeeCard.propTypes = {
  attendee: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  addGuest: PropTypes.func.isRequired,
  removeAttendee: PropTypes.func.isRequired,
  attendeeCount: PropTypes.number.isRequired,
  maxAttendees: PropTypes.number.isRequired,
};

import React from 'react';
import styled from 'styled-components';
import GlobalStyles from './GlobalStyles.js';

export const Button = styled.button`
  border-radius: calc(20px + 2em);
  border: 4px solid ${GlobalStyles.color1};
  color: ${GlobalStyles.color1};
  background-color: rgba(0, 0, 0, 0);
  font-family: ${GlobalStyles.fontFamily};
  min-width: 12em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  :hover {
    cursor: pointer;
  }

  padding: 20px 15px 20px 15px;
  font-size: ${GlobalStyles.fontSize5};

  @media ${GlobalStyles.screenSize.laptop} {
    padding: 30px 25px 30px 25px;
    font-size: ${GlobalStyles.fontSize4};
  }
`;

export const SmButton = styled.button`
  font-size: ${GlobalStyles.fontSize7};
  border-radius: calc(10px + 2em);
  border: 4px solid ${GlobalStyles.textColor1};
  color: ${GlobalStyles.textColor1};
  background-color: rgba(0, 0, 0, 0);
  font-family: ${GlobalStyles.fontFamily};
  min-width: 12em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  :hover {
    cursor: pointer;
  }

  padding: 20px 15px 20px 15px;
  font-size: ${GlobalStyles.fontSize5};
`;
// @media ${GlobalStyles.screenSize.laptop} {
//   padding: 30px 25px 30px 25px;
//   font-size: ${GlobalStyles.fontSize4};
// }

export const GlobalContainer = styled.div`
  min-height: calc(100vh - ${GlobalStyles.totalMobileBarHeight});

  @media ${GlobalStyles.screenSize.tablet} {
    min-height: calc(100vh - ${GlobalStyles.totalBarHeight});
  }
`;

export const GlobalContent = styled.div`
  background-color: rgba(0, 0, 0, 0);
  padding: 3em;
  @media ${GlobalStyles.screenSize.tablet} {
    padding: 5em;
  }
`;

export const HeaderImgSvg = styled.img`
  max-height: calc(100vh - 256px);
  min-width: 50%;
`;

export const GlobalBorder = styled.div`
  padding: 0%;
  background-color: ${GlobalStyles.color1};

  @media screen and ${GlobalStyles.screenSize.tablet} {
    padding-left: min(5%, 72px);
    padding-right: min(5%, 72px);
  }
`;

export const GlobalBorderVert = styled.div`
  padding: 0%;
  background-color: ${GlobalStyles.color1};

  @media screen and ${GlobalStyles.screenSize.tablet} {
    padding-left: min(5%, 72px);
    padding-right: min(5%, 72px);
    padding-top: min(5%, 72px);
  }
`;

export const XIcon = () => {
  return (
    <img
      style={{
        height: '2em',
        width: '2em',
      }}
      src="/vectors/icons8-close.svg"
      alt="x"
    />
  );
};

export const ClearButton = styled.button`
  background: rgba(0, 0, 0, 0);
  border: none;
  height: 1em;
  width: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: 'absolute';
`;

export const GlobalHeroBanner = styled.div`
  background-color: ${GlobalStyles.color3};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
`;

export const TooltipBubble = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  background-color: ${GlobalStyles.color1};
  padding: 5px;
  fontsize: ${GlobalStyles.fontSize6};
  cursor: pointer;
  transition: opacity 1.5s ease-in-out;
  visibility: invisible;
  width: 150px;
  right: -180px;
  top: -25px;
  opacity: 1;

  @media ${GlobalStyles.screenSize.laptop} {
    display: inline-block;
  }
`;

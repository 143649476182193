import styled from 'styled-components';
import GlobalStyles from '../Global/GlobalStyles.js';
import {
  GlobalContainer,
  GlobalContent,
  GlobalHeroBanner,
} from '../Global/global-components.js';

export const Container = styled(GlobalContainer)`
  background-color: ${GlobalStyles.color1};
`;

export const Content = styled(GlobalContent)`
  background-color: rgba(0, 0, 0, 0);
  color: ${GlobalStyles.textColor3};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const Schedule = styled.div`
  background-color: ${GlobalStyles.textColor1};
  width: 100%;
  color: ${GlobalStyles.color1};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
`;

export const ScheduleContent = styled.div`
  width: 80%;
  max-width: 1220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    width: 70%;
  }
`;

export const HeroBanner = styled(GlobalHeroBanner)``;

export const ContentTitle = styled.div`
  font-size: 64px;
  font-weight: bold;
  margin: 64px;
`;

export const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media ${GlobalStyles.screenSize.tablet} {
    height: 44px;
    flex-direction: row;
  }
`;

export const DayButton = styled.button`
  background-color: ${GlobalStyles.textColor1};
  color: ${GlobalStyles.color1};
  font-size: 30px;
  font-family: ${GlobalStyles.fontFamily};
  font-weight: bold;
  border: none;
  text-align: center;
  margin: 5px;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    font-size: 38px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const DayButtonSelected = styled(DayButton)`
  text-decoration: underline;
  background: #e37663;
`;

export const Sparkle = styled.div`
  height: 100%;
  width: 40px;
  display: none;
  position: relative;
  top: -5px;
  @media screen and (min-width: 900px) {
    display: block;
  }
`;

export const InfoGrid = styled.div`
  ${'' /* display: 'inline-block'; */}
  width: 100%;
  column-count: 1;
  gap: 0;
  grid-template-rows: 200px 200px 200px;

  @media screen and ${GlobalStyles.screenSize.laptopL} {
    column-count: 3;
    grid-template-rows: 400px;
  }
`;

export const Date = styled.div`
  font-size: 30px;
  font-family: ${GlobalStyles.fontFamily};
  font-weight: bold;
  margin: 70px 0 0 0;
`;

export const InfoCardContainer = styled.div`
  color: ${GlobalStyles.color1};
  font-size: 20px;
  padding: 20px;
  width: 100%;
  padding: 20px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media screen and ${GlobalStyles.screenSize.laptopL} {
    height: 400px;
  }
`;

export const InfoCardHeader = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 10px 0 10px 0;
  width: 80%;
`;

export const InfoCardContent1 = styled.div`
  margin: 20px;
  width: 80%;
  font-size: 24px;
  line-height: 32px;
  word-wrap: break-word;
`;

export const InfoCardAnchor = styled.a`
  width: 80%;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${GlobalStyles.color2};
`;

export const InfoCardContent2 = styled.div`
  width: 80%;
  font-size: 24px;
  line-height: 32px;
`;

export const SvgImage = styled.img`
  height: 100%;
  max-width: 80%;
  @media screen and ${GlobalStyles.screenSize.tablet} {
    max-width: 630px;
  }
`;

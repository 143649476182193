import React from 'react';
import Header from '../Header/Header.js';
import EventSummary from '../EventSummary/EventSummary.js';
import Title from '../Title/Title.js';
import Registry from '../Registry/Registry.js';
import Footer from '../Footer/Footer.js';
import { GlobalBorderVert } from '../Global/global-components.js';
import GlobalStyles from '../Global/GlobalStyles.js';

const Home = ({ mobile }) => {
  if (!mobile) {
    return (
      <div style={{ backgroundColor: GlobalStyles.textColor1 }}>
        <Header />
        <Title />
        <GlobalBorderVert data-testid="home-page" className="border">
          <EventSummary />
          <Registry />
        </GlobalBorderVert>
        <Footer />
      </div>
    );
  }

  return (
    <div data-testid="home-page">
      <Header />
      <Title />
      <EventSummary />
      <Registry />
      <Footer />
    </div>
  );
};

export default Home;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home.js';
import EventInfo from './Components/Pages/EventInfo.js';
import Photos from './Components/Pages/Photos.js';
import RSVP from './Components/Pages/RSVP.js';
import { Admin } from './Components/Pages';
import { db } from './init';

function App() {
  const mobile = window.screen.width <= 1024;
  return (
    <div className="App">
      <BrowserRouter style={{ display: 'block' }}>
        <Routes>
          <Route path="/" element={<Home mobile={mobile} />} />
          <Route path="/event-info" element={<EventInfo mobile={mobile} />} />
          <Route path="/photos" element={<Photos mobile={mobile} />} />
          <Route path="/rsvp" element={<RSVP mobile={mobile} db={db} />} />
          <Route path="/contact" element={<Home mobile={mobile} />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/*" element={<Home mobile={mobile} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
